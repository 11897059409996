import React, { useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import ContentBlock from "../shared/sub/contentBlock";
// import ContentPopUp from "../shared/sub/ContactPopUp"

import ContactContentStyle from "./ContactContent.style";
import { Query } from "@graphql-types";
import { TextButtons } from "@global";

const ContactContent = () => {
  const { sanityContactUsPage }: Query = useStaticQuery(graphql`
    query {
      sanityContactUsPage {
        title
        formTitle
        dropdownOptions
        contactDetails {
          _rawContent(resolveReferences: { maxDepth: 5 })
        }
        textAndButton {
          ...sanityTextButton
        }
      }
    }
  `);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://lateralpartners.activehosted.com/f/embed.php?id=1";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  if (!sanityContactUsPage) return null;
  const { contactDetails, formTitle, textAndButton } = sanityContactUsPage;

  console.log(textAndButton);

  return (
    <ContactContentStyle>
      <div className="contact-container container">
        <div className="contact-details">
          <ContentBlock blocks={contactDetails?._rawContent} />
          {textAndButton && <TextButtons data={[textAndButton]} />}
        </div>
        {/* <ContentPopUp width="45%" /> */}
        <div className="form">
          <h3>{formTitle}</h3>
          <div className="_form_1"></div>
        </div>
      </div>
    </ContactContentStyle>
  );
};

export default ContactContent;
