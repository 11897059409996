import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SubHeader from "../shared/subHeader"

const ContactUsHeader = () => {
  const {
    sanityContactUsPage: { title },
  } = useStaticQuery(graphql`
    query {
      sanityContactUsPage {
        title
      }
    }
  `)

  return <SubHeader title={title} />
}

export default ContactUsHeader
