import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SEO, { seoDataFormat } from "../shared/seo"

const ContactUsSEO = () => {
  const {
    sanityContactUsPage: { seo },
  } = useStaticQuery(graphql`
    query {
      sanityContactUsPage {
        seo {
          ogImage {
            asset {
              url
            }
          }
          pageDescription
          pageKeyWords
          pageTitle
          slug {
            current
          }
        }
      }
    }
  `)

  const seoData = seoDataFormat(seo)

  return <SEO {...seoData} />
}

export default ContactUsSEO
