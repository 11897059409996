import styled from "styled-components";

export default styled.section`
  width: 100%;
  background-color: var(--white);
  color: var(--red);
  margin: 80px auto 100px auto;

  @media only screen and (max-width: 600px) {
    margin: 40px auto 100px auto;
  }

  .contact-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    max-width: 1000px;
    grid-gap: 50px;

    @media only screen and (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }

  .text-button-block div {
    flex-direction: column;
    a {
      width: fit-content;
      text-decoration: none;
    }
  }

  /* CONTACT DETAILS FROM CONTENTBLOCK */

  .contact-details a:last-child {
    text-align: left;
    font-family: "medium";
  }

  h4 {
    color: var(--red);
    font-size: 1.7rem;
    margin-bottom: 5px;
  }

  h3 {
    color: var(--red);
    font-weight: lighter;
    font-size: 1.5em;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
  }

  .form {
    ._form_1 form {
      padding: 0 !important;
      margin-top: 15px !important;
      width: 100% !important;

      button._submit {
        margin-top: 1rem;

        @media only screen and (max-width: 668px) {
          font-size: 1.4rem !important;
        }
      }
    }
  }
`;
